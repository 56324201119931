import React, { useState, useRef, useEffect } from "react";


export const Team = (props) => {
  const teamRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [isMore, setIsMore] = useState(false);
  const handleMore = () => {
    setIsMore(!isMore);
  };
  const showMore = props.data && props.data.length > 4 ? true : false;
  useEffect(() => {
    const handleScroll = () => {
      if (teamRef.current) {
        const rect = teamRef.current.getBoundingClientRect();

        // Điều chỉnh điều kiện để phát hiện phần tử nằm trong viewport
        if (rect.top <= 400) {
          setIsActive(true);
        } else {
          setIsActive(false);
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div id="team" className="text-center" ref={teamRef}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 section-title">
            <h2>Meet the Team</h2>
            <p>
              Our team is a diverse group of passionate and skilled professionals dedicated to delivering exceptional results. With expertise spanning software development, UI/UX design, data processing, and cybersecurity, we work collaboratively to drive innovation and exceed client expectations. Get to know the talented individuals behind our success.
            </p>
          </div>
        </div>
        <div className="list-member">
          {props.data && !isMore
            ? props.data.slice(0, 4).map((d, i) => (
              <div key={`${d.name}-${i}`} className={`col-md-3 col-xs-12 team ${isActive ? 'team-active' : ''}`}>
                {/* <a href={d.linked_in}> */}
                <div className="thumbnail">
                  {" "}
                  <img src={d.img} alt={d.name} className="team-img" />
                  <div className="caption">
                    <h4>{d.name}</h4>
                    <p>{d.title}</p>
                    <div className="team-social">
                      {d.linked_in ? <a target="_blank" href={d.linked_in}><i className="fa fa-linkedin-square"></i></a> : ''}
                      {d.facebook ? <a target="_blank" href={d.facebook}><i className="fa fa-facebook-square"></i></a> : ''}
                    </div>
                  </div>
                </div>
                {/* </a> */}
              </div>
            ))
            : props.data && isMore
              ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className={`col-md-3 col-xs-12 team ${isActive ? 'team-active' : ''}`}>
                  {/* <a href={d.linked_in}> */}
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt={d.name} className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.title}</p>
                      <div className="team-social">
                        {d.linked_in ? <a target="_blank" href={d.linked_in}><i className="fa fa-linkedin-square"></i></a> : ''}
                        {d.facebook ? <a target="_blank" href={d.facebook}><i className="fa fa-facebook-square"></i></a> : ''}
                      </div>
                    </div>
                  </div>
                  {/* </a> */}
                </div>
              ))
              : "loading"}
        </div>
      </div>
      {showMore && isMore && <p className="team-more" onClick={handleMore}><i className="fa fa-chevron-up"></i> Hide</p>}
      {showMore && !isMore && <p className="team-more" onClick={handleMore}><i className="fa fa-chevron-down"></i> View More</p>}
    </div>
  );
};
