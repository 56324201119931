import React from 'react';
import { useParams } from 'react-router-dom';
import JsonData from "../data/data.json";

export const CaseStudiesDetail = () => {
  const params = useParams();
  const data = JsonData ? JsonData.Gallery.filter((item) => {return item.id === parseInt(params.id)}) : null;
  return (
    <div id="caseStudies-detail">
        {data.length ? data.map((item,i) => {
          return <div className='content container' key={i}>
                <h2 className='section-title'>{item.title}</h2>
                <p>{item.text}</p>
                <img src={`../${item.smallImage}`} alt={item.title} />
          </div>
        }) : <h2 className='section-title'>NOT FOUND</h2>
        }
    </div>
  )
}
