import React, { useState, useEffect } from "react";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
// import { Navigation } from "./components/navigation";
import { Team } from "./components/Team";
// import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";
import "./App.css";
// import { Outlet } from "react-router-dom";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const ScrollToSection = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      // const element = document.getElementById(hash.substring(1));
      const elementId = hash.substring(1);
      const scrollToElement = () => {
        // const currentScrollY = window.scrollY;
        // console.log('Current ScrollY:', currentScrollY);

        // Thực hiện cuộn đến phần tử đích sau khi cập nhật vị trí cuộn
        setTimeout(() => {
          scroller.scrollTo(elementId, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
          });
        }, 500);
      };

      if (document.readyState === 'complete') {
        scrollToElement();
      } else {
        window.addEventListener('load', scrollToElement);
        return () => window.removeEventListener('load', scrollToElement);
      }
    }
  }, [location]);

  return null;
};

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      {/* <Navigation /> */}n
      <ScrollToSection />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Team data={landingPageData.Team} />
      <Services data={landingPageData.Services} />
      <Gallery data={landingPageData.Gallery} />
      <Testimonials data={landingPageData.Testimonials} />
      {/* <Contact data={landingPageData.Contact} /> */}
    </div>
  );
};

export default App;
