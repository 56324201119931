import { Image } from "./image";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Gallery = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const galleryRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: windowWidth < 800 ? 1 : 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    const handleScroll = () => {
      if (galleryRef.current) {
        const rect = galleryRef.current.getBoundingClientRect();
        
        // Điều chỉnh điều kiện để phát hiện phần tử nằm trong viewport
        if (rect.top <= 500) {
          setIsActive(true);
        } else {
          setIsActive(false);
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div id="case-studies" className="text-center" ref={galleryRef}>
      <div className="container">
        <div className="section-title">
          <h2>Case studies</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>
        <div className="row">
          <div className={`portfolio-items ${isActive ? 'portfolio-items-active' : ''}`}>
          {props.data ?
          <Slider {...settings}>
            {props.data
              ? props.data.map((d, i) => {
                if (windowWidth > 800)
                  return <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-6 col-lg-6"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                      text={d.text}
                      id={d.id}
                    />
                  </div>
                else
                  return <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-6 col-lg-6"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                      text={d.text}
                      id={d.id}
                    />
                  </div>
                })
              : "Loading..."}
              </Slider>
            : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
