import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Testimonials = (props) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const testimonialsRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
  const handleScroll = () => {
    if (testimonialsRef.current) {
      const rect = testimonialsRef.current.getBoundingClientRect();
      
      // Điều chỉnh điều kiện để phát hiện phần tử nằm trong viewport
      if (rect.top <= 500) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    }
  };
  window.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', handleResize);
  return () => {
    window.removeEventListener('resize', handleResize);
    window.removeEventListener('scroll', handleScroll);
  };
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: screenWidth < 600 ? 1 : 3,
    slidesToScroll: 1
  };

  return (
    <div id="testimonials" ref={testimonialsRef}>
      <div className="container">
        <div style={{overflow: "hidden"}} className={`section-title text-center ${isActive ? 'section-title-active' : ''}`}>
          <h2>What our clients say</h2>
        </div>
        {props.data ?
          <Slider {...settings}>
            {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}+${i}`} className="item">
                    <div className="testimonial">
                      <div className="testimonial-image">
                        {" "}
                        <img src={d.img} alt="" />{" "}
                      </div>
                      <div className="testimonial-content">
                        <p>"{d.text}"</p>
                        <div className="testimonial-meta"> - {d.name} </div>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </Slider> 
        : "loading"}
      </div>
    </div>
  );
};
