import React,  { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { useLocation } from "react-router-dom";

export const Navigation = (props) => {
  const location = useLocation();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [activeHash, setActiveHash] = useState('');
  const handleNavItemClick = () => {
    setIsNavCollapsed(true);
  };
  useEffect(()=>{
    const hash = location.hash;
    setActiveHash(hash);
  }, [location])
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            onClick={()=>setIsNavCollapsed(false)}
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <NavLink className="navbar-brand page-scroll" to="/" onClick={() => scroll.scrollToTop()}>
            <span>
              <img src="../img/logo.png" alt="" />
              Pandobit
            </span>
          </NavLink>{" "}
        </div>

        <div
          className={`navbar-collapse ${isNavCollapsed ? 'collapse' : ''}`}
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <NavLink onClick={handleNavItemClick} className={`page-scroll ${activeHash === '#about' ? 'nav-active' : ''}`} to="/#about">
                About
              </NavLink>

              {/* <ScrollLink smooth={true}  to="about" className={`page-scroll ${activeHash === '#about' ? 'nav-active' : ''}`}>
                About
              </ScrollLink> */}
            </li>
            <li>
              <NavLink onClick={handleNavItemClick} className={`page-scroll ${activeHash === '#team' ? 'nav-active' : ''}`} to="/#team">
                Team
              </NavLink>
            </li>
            <li>
              <NavLink onClick={handleNavItemClick} smooth={"true"} className={`page-scroll ${activeHash === '#services' ? 'nav-active' : ''}`} to="/#services" >
                Services
              </NavLink>
              {/* <ScrollLink smooth={"true"}  to="services" className={`page-scroll ${activeHash === '#about' ? 'nav-active' : ''}`}>
                Services
              </ScrollLink> */}
            </li>
            <li>
              <NavLink onClick={handleNavItemClick} smooth={"true"} className={`page-scroll ${activeHash === '#case-studies' ? 'nav-active' : ''}`} to="/#case-studies" >
                Case studies
              </NavLink>
            </li>
            <li>
              <NavLink onClick={handleNavItemClick} smooth={"true"} className={`page-scroll ${activeHash === '#testimonials' ? 'nav-active' : ''}`} to="/#testimonials" >
                Conferences
              </NavLink>
            </li>
            <li>
              <NavLink onClick={handleNavItemClick} smooth={"true"} className={`page-scroll ${activeHash === '#contact' ? 'nav-active' : ''}`} to="/#contact" >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
