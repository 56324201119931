import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";


export const Services = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const servicesRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    const handleScroll = () => {
      if (servicesRef.current) {
        const rect = servicesRef.current.getBoundingClientRect();

        // Điều chỉnh điều kiện để phát hiện phần tử nằm trong viewport
        if (rect.top <= 200) {
          setIsActive(true);
        } else {
          setIsActive(false);
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div id="services" className="text-center" ref={servicesRef}>
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
            From software development to UI/UX design, data processing, cybersecurity, and more, our diverse range of services is tailored to meet your every need. With a commitment to excellence and a focus on efficiency, we provide dynamic solutions that drive business success.
          </p>
          <p>
            Let us be your trusted partner on the journey to achieving your goals.
          </p>
        </div>
        <div style={{ overflow: "hidden" }} className="row">
          {props.data
            ? props.data.map((d, i) => {
              if (i % 2 === 0 && windowWidth > 800)
                return <div key={`${d.name}-${i}`} className={`row services ${isActive ? 'services-active' : ''}`}>
                  {" "}
                  <div className="col-md-6 col-xs-12 image">
                    <img src={d.image} alt="" />
                  </div>
                  <div className="service-desc col-xs-12 col-md-6">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                    <Link onClick={() => scroll.scrollToTop()} to={`/services/${d.id}`}><button className="">LEARN MORE</button></Link>
                  </div>
                </div>
              else if (i % 2 !== 0 && windowWidth > 800)
                return <div key={`${d.name}-${i}`} className={`row services ${isActive ? 'services-active' : ''}`}>
                  {" "}
                  <div className="service-desc col-md-6 col-xs-12">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                    <Link onClick={() => scroll.scrollToTop()} to={`/services/${d.id}`}><button className="">LEARN MORE</button></Link>
                  </div>
                  <div className="col-md-6 col-xs-12 image">
                    <img src={d.image} alt="" />
                  </div>
                </div>
              else
                return <div key={`${d.name}-${i}`} className={`row services ${isActive ? 'services-active' : ''}`}>
                  {" "}
                  <div className="service-desc col-md-6 col-xs-12">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                    <Link onClick={() => scroll.scrollToTop()} to={`/services/${d.id}`}><button className="">LEARN MORE</button></Link>
                  </div>
                  <div className="col-md-6 col-xs-12 image">
                    <img src={d.image} alt="" />
                  </div>
                </div>
            })
            : "loading"}
        </div>
      </div>
    </div>
  );
};
