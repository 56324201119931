import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { CaseStudiesDetail } from './components/caseStudiesDetail';
import { ServicesDetail } from './components/serviceDetail';
import { Navigation } from './components/navigation';
import { Contact } from './components/contact';
import JsonData from "./data/data.json";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Navigation />
      <Routes>
        <Route path='/' element={<App />}>
        </Route>
        <Route path='/case-studies' element={<CaseStudiesDetail />}>
          <Route path=':id' element={<CaseStudiesDetail />} />
        </Route>
        <Route path='/services' element={<ServicesDetail />}>
          <Route path=':id' element={<ServicesDetail />} />
        </Route>
      </Routes>
      <Outlet />
      <Contact data={JsonData.Contact} />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
