import React, { useState, useRef, useEffect } from "react";

export const About = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const aboutRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    if (aboutRef.current) {
      // eslint-disable-next-line
      const rect = aboutRef.current.getBoundingClientRect();
      // console.log('About component position:', rect);
    }
    const handleScroll = () => {
      if (aboutRef.current) {
        const rect = aboutRef.current.getBoundingClientRect();

        // Điều chỉnh điều kiện để phát hiện phần tử nằm trong viewport
        if (rect && rect.top <= 300) {
          setIsActive(true);
        } else {
          setIsActive(false);
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div id="about" ref={aboutRef}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
              <h2>About Us</h2>
              {props.data
                ? props.data.content.map((d, i) => {
                  if (i % 2 !== 0 && windowWidth > 800)
                    return <div key={i} className="row content content--two">
                      <div className={`col-sm-12 col-md-6`}>
                        {" "}
                        <img src={d.image} className={`img-responsive ${isActive ? 'about-active' : ''}`} alt="" />{" "}
                      </div>
                      <div className={`col-sm-12 col-md-6 content-text ${isActive ? 'about-active' : ''}`}>
                        <h3>{d.title}</h3>
                        {props.data
                          ? d.paragraph.map((p, i) => (
                            <p key={i}>{p}</p>
                          )
                          )
                          : "loading"}
                      </div>
                    </div>
                  else if (i % 2 === 0 && windowWidth > 800)
                    return <div key="i" className="row content content">
                      <div className={`col-sm-12 col-md-6 content-text ${isActive ? 'about-active' : ''}`}>
                        <h3>{d.title}</h3>
                        {props.data
                          ? d.paragraph.map((p, i) => (
                            <p key={i}>{p}</p>
                          )
                          )
                          : "loading"}
                      </div>
                      <div className={`col-sm-12 col-md-6`}>
                        {" "}
                        <img src={d.image} className={`img-responsive ${isActive ? 'about-active' : ''}`} alt="" />{" "}
                      </div>
                    </div>
                  else
                    return <div key={i} className="row content content--two">
                      <div className={`col-sm-12 col-md-6`}>
                        {" "}
                        <img src={d.image} className={`img-responsive ${isActive ? 'about-active' : ''}`} alt="" />{" "}
                      </div>
                      <div className={`col-sm-12 col-md-6 content-text ${isActive ? 'about-active' : ''}`}>
                        <h3>{d.title}</h3>
                        {props.data
                          ? d.paragraph.map((p, i) => (
                            <p key={i}>{p}</p>
                          )
                          )
                          : "loading"}
                      </div>
                    </div>
                })
                : "loading"}
              <div className="row footer">
                {props.data
                  ? props.data.footer.map((p, i) => (
                    <div key={`${p.title}-${i}`} className="col-xs-12 col-md-4">
                      <h3><i className="fa fa-check"></i>{p.title}</h3>
                      <p>{p.paragraph}</p>
                    </div>
                  )
                  )
                  : "loading"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
